.input {
  width: 100%;
  min-height: 2.5em;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem;
  border: 2px solid #C4C4C4;
  font-size: 1rem;
}
