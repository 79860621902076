.users-list {
  &__header {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 1px;
    height: 2.4286rem;
    background: #3558BD;
    font-size: 0.9rem;
    padding-left: 0.8rem;
  }
  &__filter-block {
    padding: 0.7rem;
    position: relative;
  }
  &__search-icon {
    position: absolute;
    left: 1.2rem;
    top: calc(50% - 0.5rem);
    &-svg {
      width: 1rem;
    }
  }

  &__subheader {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 2px;
    &-text, &-count {
      width: 100%;
      height: 1.4286rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8571rem;
      padding: 0 0.9rem;
      box-sizing: border-box;
    }
    &-text {
      color: #fff;
      background: #585858;
    }
  }
  &__bold-text {
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7857rem;
    height: 2.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    background: #fff;
    border-top: 1px solid #ddd;
    &-nick {
      color:black;
      font-weight: bold;
    }
    &-balance {
      margin-right: 0.7rem;
    }
    &:nth-child(2n) {
      background: #f8f8f8;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__arrow-box {
    width: 1.5714rem;
    height: 1.5714rem;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1.0714rem;
      height: 1.0714rem;
    }
  }

}