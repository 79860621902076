.information {
  padding: 0.5rem;
  &__block-info {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__verify {
    text-align: center;
    padding: 1rem;
    font-size: 1.15rem;
  }
  &__button-data {
    margin-top: 1rem;
    width: 50%;
    height: 2.5rem;
    background: #FFC900;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0E1E78;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    &:disabled {
      opacity: 0.5;
    }
  }
  &__button-code {
    margin-top: 1rem;
    width: 32%;
    height: 2.5rem;
    border: 2px solid #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0E1E78;
    cursor: pointer;
    border-left: none;
    // text-transform: uppercase;
    font-weight: bold;
    position: absolute;
    top: 5.4rem;
    right: 0.5rem;
    z-index: 1;
  }
  .input {
    margin-top: 1rem;
  }
  &__input-mail{
    width: 100%;
    min-height: 2.5em;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.5rem;
    border: 2px solid #C4C4C4;
    font-size: 1rem;
    border-right: none;
    padding-right: 7.2rem;
  }
  &__input-code{
    width: 100%;
    min-height: 2.5em;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.5rem;
    border: 2px solid #C4C4C4;
    font-size: 1rem;
    margin-top: 1rem;
  }
}