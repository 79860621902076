.paginator {
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &__title {
    margin-right: 1rem;
    font-weight: bold;
    font-size: 0.875rem;
    color: #000;
  }

  &__main {
    width: fit-content;
    min-width: 3.625rem;
    height: 100%;
    position: relative;
    user-select: none;
  }
  &__items {
    width: 100%;
    max-height: 21rem;
    z-index: 2;
    position: absolute;
    top: 100%;
    background: #0e1e78;

    overflow: auto;
    border-top: none;
    border-radius: 0 0 0.625rem 0.625rem;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 0.1875rem;
      background: gray;
      cursor: pointer;
    }
  }

  &__item {
    height: 3.125rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 1.0625rem 0 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: white;
    background: #2b2d42;

    &:hover {
      background: #0e1e78;
    }

    &_current {
      height: 100%;

      border-radius: 0.625rem;
    }
  }
  &__main_open &__item_current {
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &__item-value {
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: capitalize;
  }
  &__item-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    /* position: absolute;
    right: 0.625rem; */
    margin-left: 0.75rem;

    &-svg {
      width: 0.9375rem;
      height: 1.25rem;
    }
  }
  &__main_open &__item-arrow {
    transform: scaleY(-1);
  }
}
