.locale {
  background: #fff;
  &__title {
    background:#3558BD;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
  &__item {
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    border-bottom: 1px solid #bbb;
    background: white;
    &_active {
      background: #b0bfd6;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: #FFC900;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: black;
    cursor: pointer;
  }
  // &__ok {
  //   width: 1.8rem;
  //   position: absolute;
  //   right: 1rem;
  // }
}