.spinner {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  &__loader ,
  &__loader:after {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }
  &__loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5rem solid rgba(0, 0, 0, 0.1);
    border-right: 0.5rem solid rgba(0, 0, 0, 0.1);
    border-bottom: 0.5rem solid rgba(0, 0, 0, 0.1);
    border-left: 0.5rem solid #FFC900;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}