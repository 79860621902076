.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  &__item {
    width: 100%;
    height: 2.8rem;
    border-bottom: 2px solid #bbb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    color: #333;
  }
  &__image {
    width: 1.2rem;
    &_isVerify {
      &_true {
        * {
          fill: #fff;
        }
      }
    }
    * {
      // fill: #333;
    }
  }
  &__title {
    background: #0E1E78;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: normal;
  }
}